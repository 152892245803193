@charset "UTF-8";
@font-face {
  font-family: "GTEesti";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/GT-Eesti-Display-Regular.eot");
  src: url("./assets/fonts/GT-Eesti-Display-Regular.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/GT-Eesti-Display-Regular.otf") format("otf"), url("./assets/fonts/GT-Eesti-Display-Regular.woff2") format("woff2"), url("./assets/fonts/GT-Eesti-Display-Regular.woff") format("woff"), url("./assets/fonts/GT-Eesti-Display-Regular.ttf") format("truetype"), url("./assets/fonts/GT-Eesti-Display-Regular.svg#svgFontName") format("svg");
}
@font-face {
  font-family: "GTEesti";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/GT-Eesti-Display-Regular-2.eot");
  src: url("./assets/fonts/GT-Eesti-Display-Regular-2.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/GT-Eesti-Display-Regular-2.otf") format("otf"), url("./assets/fonts/GT-Eesti-Display-Regular-2.woff2") format("woff2"), url("./assets/fonts/GT-Eesti-Display-Regular-2.woff") format("woff"), url("./assets/fonts/GT-Eesti-Display-Regular-2.ttf") format("truetype"), url("./assets/fonts/GT-Eesti-Display-Regular-2.svg#svgFontName") format("svg");
}
@font-face {
  font-family: "GTEesti";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/GT-Eesti-Display-Bold.eot");
  src: url("./assets/fonts/GT-Eesti-Display-Bold.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/GT-Eesti-Display-Bold.otf") format("otf"), url("./assets/fonts/GT-Eesti-Display-Bold.woff2") format("woff2"), url("./assets/fonts/GT-Eesti-Display-Bold.woff") format("woff"), url("./assets/fonts/GT-Eesti-Display-Bold.ttf") format("truetype"), url("./assets/fonts/GT-Eesti-Display-Bold.svg#svgFontName") format("svg");
}
@font-face {
  font-family: "GTEesti";
  font-style: normal;
  font-weight: 800;
  src: url("./assets/fonts/GT-Eesti-Display-Bold-2.eot");
  src: url("./assets/fonts/GT-Eesti-Display-Bold-2.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/GT-Eesti-Display-Bold-2.otf") format("otf"), url("./assets/fonts/GT-Eesti-Display-Bold-2.woff2") format("woff2"), url("./assets/fonts/GT-Eesti-Display-Bold-2.woff") format("woff"), url("./assets/fonts/GT-Eesti-Display-Bold-2.ttf") format("truetype"), url("./assets/fonts/GT-Eesti-Display-Bold-2.svg#svgFontName") format("svg");
}
header{
  position: fixed;
  top: 0px;
  width: 100%;
  height: 6rem;
}
@media (max-width: 993px){
  header{
    height: 5rem;
  }
}
@media (max-width: 768px){
  header{
    height: 3rem;
  }
}
header {
  z-index: 998;
}
header .header-wave{
  position: absolute;
  top: 100%;
  left: 0px;
  height: 4rem;
  width: 100%;
}
@media (max-width: 1600px){
  header .header-wave{
    height: 3rem;
  }
}
@media (max-width: 993px){
  header .header-wave{
    height: 2rem;
  }
}
header .header-wave {
  margin-top: -1px;
  background: url("assets/images/header-wave.svg");
  background-size: 100% auto;
  background-position: center 100%;
  background-repeat: no-repeat;
}
header .header-wave.header-home-wave {
  background: url("assets/images/header-home-wave.svg");
  background-size: 100% auto;
  background-position: center 100%;
  background-repeat: no-repeat;
}

.header {
  z-index: 10;
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  height: 6rem;
}

@media (max-width: 993px){
  .header{
    height: 5rem;
  }
}

@media (max-width: 768px){
  .header{
    height: 3rem;
  }
}

.header{
  padding-left: 3rem;
  padding-right: 3rem;
}

@media (max-width: 993px){
  .header{
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (max-width: 768px){
  .header{
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.header.home-background{
  padding-left: 0px;
  padding-right: 0px;
}
.header .logo-link{
  grid-column: span 4 / span 4;
  grid-column-start: 1;
}
@media (max-width: 993px){
  .header .logo-link{
    grid-column: span 4 / span 4;
  }
}
@media (max-width: 768px){
  .header .logo-link{
    grid-column: span 5 / span 5;
  }
}
@media (max-width: 480px){
  .header .logo-link{
    grid-column: span 7 / span 7;
  }
}
.header .logo-link{
  max-height: 7rem;
  float: left;
}
.header .logo-link .logo{
  height: 2.5rem;
  width: auto;
}
.header .header-nav-container{
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  grid-column-start: 1;
  grid-column-end: 25;
  height: 7rem;
}
@media (max-width: 768px){
  .header .header-nav-container{
    height: 5rem;
  }
}
.header .header-nav-container {
  align-items: center;
  z-index: 999;
}
@media (max-width: 1100px) {
  .header .header-nav-container{
    flex-direction: row-reverse;
  }
}
.header .header-button-container{
  display: none;
  gap: 0.5rem;
}
.dashboard-container .dashboard-content input:not(.multiselect__input).header .header-button-container {
  display: none;
}
@media (max-width: 768px){
  .header .header-button-container{
    gap: 1rem;
  }
}
@media (max-width: 1100px){
  .header .header-button-container{
    display: flex;
  }
}
.header .header-button-container {
  align-items: center;
}
.header .nav{
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1100px){
  .header .nav{
    display: none;
  }
}
.header .nav{
  height: 4rem;
  gap: 2.5rem;
}
@media (max-width: 1200px){
  .header .nav{
    gap: 1rem;
  }
}
.header .nav {
  align-items: center;
}
.header .login-nav{
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1100px){
  .header .login-nav{
    display: none;
  }
}
.header .login-nav{
  height: 4rem;
  gap: 2.5rem;
}
@media (max-width: 1200px){
  .header .login-nav{
    gap: 1rem;
  }
}
.header .login-nav {
  align-items: center;
}
.header .nav-item{
  display: inline-block;
  cursor: pointer;
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
@media (min-width: 1200px){
  .header .nav-item:hover{
    --tw-text-opacity: 1;
    color: rgb(63 206 136 / var(--tw-text-opacity));
  }
}
.header .nav-item + .header-nav-item{
  margin-left: 2rem;
}
@media (min-width: 1200px){
  .header.home-background .nav-item:hover{
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}
.header.home-background .nav-item{
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
@media (min-width: 1200px){
  .header.home-background .btn:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }
  .header.home-background .btn:hover{
    --tw-text-opacity: 1;
    color: rgb(26 26 26 / var(--tw-text-opacity));
  }
}
.header.home-background .btn{
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.header.home-background .anmelden-button{
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 0.75rem;
}
@media (min-width: 1200px){
  .header.home-background .anmelden-button:hover{
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}
.header.home-background .anmelden-button{
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.overlay-nav-container{
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  gap: 3rem;
}
.overlay-nav-container .nav{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.overlay-nav-container .login-nav{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.menu-dropdown{
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  --tw-shadow-colored: 0 3px 6px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  width: 15rem;
  right: 50%;
  height: auto;
  overflow: hidden;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  z-index: 99;
  transform: translateX(50%);
}
@media (min-width: 1200px){
  .menu-dropdown a:hover{
    --tw-text-opacity: 1;
    color: rgb(63 206 136 / var(--tw-text-opacity));
  }
}
.menu-dropdown a{
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
@media (min-width: 1200px){
  .menu-dropdown a.logout:hover{
    --tw-text-opacity: 1;
    color: rgb(255 40 40 / var(--tw-text-opacity));
  }
}
.menu-dropdown a.logout{
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.language-dropdown{
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  --tw-shadow-colored: 0 3px 6px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  right: 50%;
  z-index: 50;
  height: auto;
  overflow: hidden;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transform: translateX(50%);
}
.language-dropdown.not-active{
  height: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.title-container{
  position: relative;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  margin-left: -3rem;
  margin-right: -3rem;
}

@media (max-width: 993px){
  .title-container{
    margin-left: -2rem;
    margin-right: -2rem;
  }
}

@media (max-width: 768px){
  .title-container{
    margin-left: -1rem;
    margin-right: -1rem;
  }
}

.title-container{
  padding-left: 3rem;
  padding-right: 3rem;
}

@media (max-width: 993px){
  .title-container{
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (max-width: 768px){
  .title-container{
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.title-container{
  margin-bottom: 8rem;
  padding-top: 11rem;
  padding-bottom: 4rem;
  margin-top: -12rem;
}

@media (max-width: 993px){
  .title-container{
    margin-top: -9rem;
  }
}

@media (max-width: 768px){
  .title-container{
    margin-top: -8rem;
  }
}

.title-container{
  line-height: 1.25;
}

@media (max-width: 768px){
  .title-container{
    margin-bottom: 2rem;
  }
  .title-container{
    padding-bottom: 0px;
  }
  .title-container{
    padding-top: 7rem;
  }
}
.title-container h1{
  text-align: center;
  font-size: 2.125rem;
  font-weight: 700;
}
.title-container .header-wave{
  position: absolute;
  top: 100%;
  left: 0px;
  height: 8rem;
  width: 100%;
}
@media (max-width: 993px){
  .title-container .header-wave{
    height: 4rem;
  }
}
.title-container .header-wave {
  background: url("assets/images/header-wave.svg");
  background-size: 100% auto;
  background-position: center 100%;
  background-repeat: no-repeat;
}

.anmelden-button{
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 0.75rem;
}

@media (min-width: 1200px){
  .anmelden-button:hover{
    --tw-text-opacity: 1;
    color: rgb(63 206 136 / var(--tw-text-opacity));
  }
}

.anmelden-button{
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.bounce-fix{
  position: absolute;
  top: -16rem;
  height: 16rem;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  z-index: 999;
}

footer{
  width: 100%;
}

.footer-wrapper{
  --tw-bg-opacity: 1;
  background-color: rgb(63 206 136 / var(--tw-bg-opacity));
  padding-left: 3rem;
  padding-right: 3rem;
}

@media (max-width: 993px){
  .footer-wrapper{
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (max-width: 768px){
  .footer-wrapper{
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.footer{
  z-index: 10;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  max-width: 1600px;
  grid-template-columns: repeat(12, 1fr);
  row-gap: 3rem;
}

.footer-wave{
  height: 8rem;
  background: url("assets/images/footer.svg");
  background-size: 100% auto;
  margin-bottom: -1px;
}

.footer-image-cell{
  grid-column: span 5 / span 5;
  grid-column-start: 3;
}
.footer-image-cell .footer-logo-image{
  margin-bottom: 1rem;
  height: auto;
  width: 6rem;
}

.footer-cell{
  grid-column: span 3 / span 3;
}

@media (max-width: 993px){
  .footer-cell{
    grid-column: span 6 / span 6;
  }
}

@media (max-width: 480px){
  .footer-cell{
    grid-column: span 12 / span 12;
  }
}

.footer-title{
  margin-bottom: 1rem;
  font-weight: 700;
}

.footer-links{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

@media (min-width: 1200px){
  .footer-link:hover{
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}

.footer-link{
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.sub-footer{
  grid-column: span 12 / span 12;
  grid-column-start: 1;
  justify-self: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  width: 100%;
  border-top-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(26 26 26 / var(--tw-border-opacity));
}

.social-footer{
  grid-column: span 12 / span 12;
  grid-column-start: 1;
  display: flex;
  justify-content: center;
  justify-self: center;
  padding-top: 3rem;
  width: 100%;
  gap: 1rem;
  border-top-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(26 26 26 / var(--tw-border-opacity));
}

.social-footer-link {
  font-size: 1.2rem;
}

.sub-footer-container{
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.copyright-text{
  grid-column: span 6 / span 6;
  grid-column-start: 1;
}

.sub-footer-social{
  grid-column: span 6 / span 6;
  display: flex;
  gap: 1.5rem;
  justify-content: flex-end;
  font-size: 1rem;
  align-items: center;
}

.sub-footer-link{
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-transform: uppercase;
}

.sub-footer-copyright{
  grid-column: span 19 / span 19;
  grid-column-start: 4;
  justify-self: center;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.prefooter-wrapper{
  padding-left: 3rem;
  padding-right: 3rem;
}

@media (max-width: 993px){
  .prefooter-wrapper{
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (max-width: 768px){
  .prefooter-wrapper{
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.prefooter-wrapper{
  margin-bottom: -2.5rem;
}

.prefooter{
  margin-left: auto;
  margin-right: auto;
  display: grid;
  max-width: 1600px;
  grid-template-columns: repeat(12, 1fr);
  position: relative;
  padding-top: 2rem;
}

.prefooter-image{
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  grid-column: span 5 / span 5;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-row-end: 2;
  padding-right: 15%;
}

@media (max-width: 768px){
  .prefooter-image{
    padding-top: 2rem;
  }
}

@media (max-width: 993px){
  .prefooter-image{
    position: absolute;
  }
  .prefooter-image{
    bottom: 0px;
  }
}

@media (max-width: 768px){
  .prefooter-image{
    position: static;
  }
  .prefooter-image{
    bottom: auto;
  }
  .prefooter-image{
    grid-column: span 12 / span 12;
  }
  .prefooter-image{
    grid-row-start: 2;
  }
}

.app-main{
  padding-top: 12rem;
}

@media (max-width: 993px){
  .app-main{
    padding-top: 9rem;
  }
}

@media (max-width: 768px){
  .app-main{
    padding-top: 8rem;
  }
}

.app-main{
  padding-left: 3rem;
  padding-right: 3rem;
}

@media (max-width: 993px){
  .app-main{
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (max-width: 768px){
  .app-main{
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.block-container{
  z-index: 10;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  max-width: 1600px;
  grid-template-columns: repeat(24, 1fr);
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.center-x{
  left: 50%;
  transform: translateX(-50%);
}

.center-y{
  top: 50%;
  transform: translateY(-50%);
}

.home-background {
  background: transparent;
}

.gradient-bg {
  background: linear-gradient(to right, #ffff4f 10%, #2dca7c 70%, #2dafb3 100%);
}

.block-intro{
  position: relative;
}
.block-intro .intro-block-container{
  position: absolute;
  width: 100%;
  top: 50%;
  z-index: 50;
  transform: translate(0, -50%);
}
.block-intro .intro-block-container .block-container{
  position: relative;
}
.block-intro .intro-block-container .block-container .content{
  grid-column-start: 3;
  grid-column-end: 23;
}
.block-intro .background{
  grid-column-start: 1;
  grid-column-end: 25;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 90vh;
}
.block-intro .sliding-background .bild{
  position: absolute;
  top: 0px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 90vh;
}
.block-intro .sliding-background .rendered{
  width: 100%;
  height: 90vh;
}

.intro-wave{
  position: absolute;
  top: 100%;
  left: 0px;
  z-index: 0;
  height: 16rem;
  width: 100%;
}

@media (max-width: 993px){
  .intro-wave{
    height: 8rem;
  }
}

.intro-wave {
  margin-top: -1px;
  background: url("assets/images/header-home-wave-high.svg");
  background-size: 100% auto;
  background-position: center 50%;
  background-repeat: no-repeat;
}

.block-intro{
  position: relative;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  margin-left: -3rem;
  margin-right: -3rem;
  margin-bottom: 20rem;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 5rem;
}

@media (max-width: 993px){
  .block-intro{
    margin-left: -2rem;
    margin-right: -2rem;
  }
  .block-intro{
    margin-bottom: 16rem;
  }
  .block-intro{
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .block-intro{
    padding-top: 4rem;
  }
}

@media (max-width: 768px){
  .block-intro{
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .block-intro{
    margin-bottom: 12rem;
  }
  .block-intro{
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.block-intro{
  margin-top: -6rem;
}

@media (max-width: 993px){
  .block-intro{
    margin-top: -4rem;
  }
}

.block-intro {
  z-index: 998;
  background: linear-gradient(to right, #ffff4f 10%, #2dca7c 70%, #2dafb3 100%);
}

.home-search{
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  margin-top: 6rem;
  display: flex;
}

.dropdown-title{
  height: 100%;
  align-items: center;
  gap: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
}
.dropdown-title > i{
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translate(0, -50%);
}
.dropdown-title > div{
  align-items: center;
  gap: 0.5rem;
}
.dropdown-title .selected-kanton, .dropdown-title .selected-jobart{
  display: inline;
}

.search-dropdown{
  position: absolute;
  z-index: 50;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 1rem;
  --tw-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  --tw-shadow-colored: 0 3px 6px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  max-height: 13rem;
  overflow: auto;
}

.home-illu{
  grid-column-start: 1;
  grid-column-end: 9;
  grid-row: span 1 / span 1;
  grid-row-start: 1;
}

@media (max-width: 993px){
  .home-illu{
    grid-column-start: 1;
  }
  .home-illu{
    grid-column-end: 13;
  }
  .home-illu{
    grid-row: span 1 / span 1;
  }
  .home-illu{
    grid-row-start: 2;
  }
}

.home-illu{
  z-index: 40;
}

.intro-title-block{
  position: relative;
  margin-top: 4rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 48rem;
}
@media (max-width: 993px) {
  .intro-title-block {
    left: 16px;
  }
}

.intro-search-block{
  z-index: 50;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  height: 23vw;
}
@media (max-width: 1600px) {
  .intro-search-block {
    height: 20vw;
  }
}
@media (max-width: 993px) {
  .intro-search-block {
    height: 60vw;
  }
}

.intro-container{
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
}

.block-grid4IconText{
  position: relative;
  grid-column-start: 3;
  grid-column-end: 23;
  --tw-bg-opacity: 1;
  background-color: rgb(63 206 136 / var(--tw-bg-opacity));
  padding: 4rem;
}
.block-grid4IconText .link{
  position: absolute;
  right: 4rem;
  top: 4rem;
}
.block-grid4IconText .item-container{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 4rem;
}
.block-grid4IconText .item-container .item .icon{
  height: 3rem;
  width: 3rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.block-grid3ImageTextLink{
  position: relative;
  grid-column-start: 3;
  grid-column-end: 23;
}
.block-grid3ImageTextLink .item-container{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4rem;
}
.block-grid3ImageTextLink .item-container .item .bild{
  width: 100%;
  padding-top: 56.25%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.block-grid3Product{
  position: relative;
  grid-column-start: 3;
  grid-column-end: 23;
}
.block-grid3Product .item-container{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4rem;
}
.block-grid3Product .item-container .item .bild{
  width: 100%;
  padding-top: 56.25%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.block-grid3Product .item-container .item .merkmale{
  list-style-type: disc;
  padding-left: 1.25rem;
}
.block-customerTestimonials{
  position: relative;
  grid-column-start: 3;
  grid-column-end: 23;
  --tw-bg-opacity: 1;
  background-color: rgb(221 221 221 / var(--tw-bg-opacity));
  padding: 4rem;
}
.block-customerTestimonials .logo-container{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.block-customerTestimonials .logo-container .logo{
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 56.25%;
}
.block-customerTestimonials .testimonial-slider .testimonial{
  display: flex;
}
.block-customerTestimonials .testimonial-slider .testimonial .portrait{
  height: 8rem;
  width: 8rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 9999px;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 8rem;
}
.block-filter{
  position: relative;
  grid-column-start: 3;
  grid-column-end: 23;
  text-align: center;
}
.block-filter .filter-buttons .filter-button{
  display: inline-block;
  border-radius: 9999px;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(26 26 26 / var(--tw-border-opacity));
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.block-grid4Product{
  position: relative;
  grid-column-start: 3;
  grid-column-end: 23;
}
.block-grid4Product .item-container{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 4rem;
}
.block-grid4Product .item-container .item .bild{
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 100%;
}
.block-kontakt{
  position: relative;
  grid-column-start: 3;
  grid-column-end: 23;
  --tw-bg-opacity: 1;
  background-color: rgb(63 206 136 / var(--tw-bg-opacity));
  padding: 4rem;
  display: flex;
}
.block-kontakt .portrait{
  height: 8rem;
  width: 8rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 9999px;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 8rem;
}
.block-kontakt .content .link{
  position: absolute;
  right: 4rem;
  top: 4rem;
}

.block-aufzaehlung{
  position: relative;
  grid-column-start: 4;
  grid-column-end: 22;
}
.block-aufzaehlung .item-container .item{
  display: flex;
}
.block-aufzaehlung .item-container .item .icon{
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 8rem;
  border-radius: 9999px;
}
.block-aufzaehlung .item-container .item .bild{
  height: 8rem;
  width: 8rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 9999px;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 8rem;
}
.block-zeitstrahl{
  position: relative;
  grid-column-start: 4;
  grid-column-end: 22;
}
.block-zeitstrahl .item-container .item{
  display: flex;
}
.block-zeitstrahl .item-container .item .icon{
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 8rem;
  border-radius: 9999px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.block-text{
  position: relative;
  grid-column-start: 5;
  grid-column-end: 21;
}
.block-text a{
  --tw-text-opacity: 1;
  color: rgb(229 245 246 / var(--tw-text-opacity));
}
@media (min-width: 1200px){
  .block-text a:hover{
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
  }
}
.block-text p{
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.block-text i, .block-text em{
  font-weight: 500;
}
.block-text blockquote{
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-left: 1.5rem;
  --tw-border-opacity: 1;
  border-color: rgb(229 245 246 / var(--tw-border-opacity));
  padding-left: 0.75rem;
  border-left-width: 3px;
  font-style: italic;
}
.block-text ul, .block-text ol{
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-left: 2.25rem;
}
.block-text ul > li + li, .block-text ol > li + li{
  margin-top: 0.25rem;
}
.block-text ul > li > ol, .block-text ul > li > ul, .block-text ol > li > ol, .block-text ol > li > ul{
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.block-text ul{
  list-style-type: disc;
}
.block-text ol{
  list-style-type: decimal;
}

.block-teamEinzelperson-bild{
  position: relative;
  grid-column-start: 3;
  grid-column-end: 11;
}
.block-teamEinzelperson-bild .portrait{
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.block-teamEinzelperson-content{
  position: relative;
  grid-column-start: 12;
  grid-column-end: 23;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.block-akkordeon{
  position: relative;
  grid-column-start: 4;
  grid-column-end: 22;
}
.block-akkordeon .item-container .item{
  margin-bottom: 1rem;
  --tw-bg-opacity: 1;
  background-color: rgb(221 221 221 / var(--tw-bg-opacity));
}
.block-akkordeon .item-container .item .titel{
  padding: 2rem;
}
.block-akkordeon .item-container .item .text{
  display: none;
  padding: 2rem;
}
.dashboard-container .dashboard-content input:not(.multiselect__input).block-akkordeon .item-container .item .text {
  display: none;
}
.block-akkordeon .item-container .item .text.akkordeon-active{
  display: block;
}
.block-akkordeon .item-container .item .akkordeon-icon{
  position: absolute;
  right: 2rem;
}

.akkordeon{
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.akkordeon .akkordeon-icon{
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
@media (min-width: 1200px) {
  .akkordeon:hover .akkordeon-icon{
    font-weight: 700;
  }
}
.akkordeon .titel{
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  font-size: 1.375rem;
  font-weight: 700;
  border-radius: 0.5rem;
  --tw-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  --tw-shadow-colored: 0 3px 6px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.akkordeon .content{
  height: 0px;
  overflow: hidden;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 2rem;
  padding-right: 2rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.akkordeon .content.akkordeon-active{
  height: auto;
  padding: 2rem;
}
.akkordeon .content .side-menu{
  padding: 2rem;
  padding-top: 0px;
}

.block-bildRegler{
  position: relative;
  grid-column-start: 3;
  grid-column-end: 23;
}
.block-bildRegler .bild{
  position: absolute;
  top: 0px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 500px;
}
.block-bildRegler .rendered{
  width: 100%;
  height: 500px;
}

.block-chat{
  position: relative;
  grid-column-start: 3;
  grid-column-end: 23;
  --tw-bg-opacity: 1;
  background-color: rgb(63 206 136 / var(--tw-bg-opacity));
  padding: 4rem;
  display: flex;
}
.block-chat .portrait{
  height: 8rem;
  width: 8rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 9999px;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 8rem;
}
.block-chat .content .link{
  position: absolute;
  right: 4rem;
  top: 4rem;
}

.block-gallery-bild{
  position: relative;
  grid-column-start: 4;
  grid-column-end: 10;
}
.block-gallery-bild .bild{
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.block-gallery-bild .gallery{
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.block-gallery-bild .gallery .bild{
  position: relative;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.block-gallery-bild .gallery .bild .filter-layer{
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(46, 59, 78, 0.5);
}
.block-gallery-bild .gallery .bild .open-button{
  position: absolute;
  top: 50%;
  left: 50%;
  height: 3rem;
  width: 3rem;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  transform: translate(-50%, -50%);
}

.block-gallery-content{
  position: relative;
  grid-column-start: 11;
  grid-column-end: 22;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.lightbox{
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.9);
}
.lightbox .close-button{
  position: fixed;
  top: 3rem;
  right: 3rem;
  height: 3rem;
  width: 3rem;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.lightbox .prev-button{
  position: fixed;
  left: 3rem;
  top: 50%;
  height: 3rem;
  width: 3rem;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  transform: translate(0, -50%);
}
.lightbox .next-button{
  position: fixed;
  right: 3rem;
  top: 50%;
  transform: translate(0, -50%);
  height: 3rem;
  width: 3rem;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.lightbox .lightbox-container{
  position: fixed;
  right: 8rem;
  left: 8rem;
  top: 50%;
  height: 75%;
  max-height: 100%;
  max-width: 100%;
  transform: translate(0, -50%);
}
.lightbox .lightbox-container .lightbox-bild{
  height: 100%;
  width: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.lightbox .lightbox-container .lightbox-text{
  text-align: center;
}

.block-grid3Team{
  position: relative;
  grid-column-start: 3;
  grid-column-end: 23;
}
.block-grid3Team .item-container{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4rem;
}
.block-grid3Team .item-container .item .bild{
  width: 100%;
  padding-top: 56.25%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.block-jobs{
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
}
.block-jobs .jobs{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}
@media (max-width: 768px){
  .block-jobs .jobs{
    grid-template-columns: repeat(1, 1fr);
  }
}
.block-jobs .jobs .job{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  padding: 1rem;
}
.block-jobs .jobs-heading{
  text-align: center;
  font-size: 2.125rem;
  font-weight: 700;
}
.block-jobs .jobs-lead{
  text-align: center;
}
.block-jobs .funktionen{
  margin-bottom: 4rem;
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.block-grid{
  margin-top: 16rem;
  margin-bottom: 8rem;
  padding-bottom: 0.5rem;
}

@media (max-width: 993px){
  .block-grid{
    margin-top: 8rem;
  }
  .block-grid{
    margin-bottom: 4rem;
  }
}

.info-top-wave{
  position: absolute;
  top: -8rem;
  left: 0px;
  height: 8rem;
  width: 100%;
}

@media (max-width: 993px){
  .info-top-wave{
    top: -4rem;
  }
  .info-top-wave{
    height: 4rem;
  }
}

.info-top-wave {
  background: url("assets/images/infoblock-top-wave.svg");
  background-size: 100% auto;
  background-position: center 0%;
  background-repeat: no-repeat;
}

.info-bottom-wave{
  position: absolute;
  top: 100%;
  left: 0px;
  height: 8rem;
  width: 100%;
}

@media (max-width: 993px){
  .info-bottom-wave{
    height: 4rem;
  }
}

.info-bottom-wave {
  background: url("assets/images/infoblock-bottom-wave.svg");
  background-size: 100% auto;
  background-position: center 100%;
  background-repeat: no-repeat;
}

.inhaltsseite-wave{
  position: absolute;
  top: 100%;
  left: 0px;
  height: 8rem;
  width: 100%;
}

@media (max-width: 993px){
  .inhaltsseite-wave{
    height: 4rem;
  }
}

.inhaltsseite-wave {
  background: url("assets/images/header-wave.svg");
  background-size: 100% auto;
  background-position: center 100%;
  background-repeat: no-repeat;
}

.info-block{
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  padding-bottom: 3rem;
}

.info{
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
@media (min-width: 1200px) {
  .info:hover{
    z-index: 50;
  }
  .info:hover{
    --tw-scale-x: 1.03;
    --tw-scale-y: 1.03;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

.preisgestlatung-image{
  position: absolute;
  top: 100%;
  width: 24rem;
  max-width: 90%;
}

.overlay{
  position: fixed;
  overflow: hidden;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 998;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.2s, opacity 0.2s linear 0s;
  pointer-events: none;
}
.overlay.overlay-show {
  pointer-events: all;
  overflow-y: scroll;
  z-index: 998;
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 0.2s linear 0s;
}
.overlay.overlay-show.overlay-navigation-mobile, .overlay.overlay-show.overlay-menu-kandidat, .overlay.overlay-show.overlay-menu-arbeitgeber {
  z-index: 997;
}
.overlay .overlay-inner{
  height: 100%;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.overlay-navigation-mobile, .overlay-menu-kandidat, .overlay-menu-arbeitgeber {
  z-index: 997 !important;
}

.overlay-navigation-mobile{
  --tw-bg-opacity: 1;
  background-color: rgb(63 206 136 / var(--tw-bg-opacity));
}

.overlay-menu-kandidat, .overlay-menu-arbeitgeber{
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.overlay-login, .overlay-netzwerk, .overlay-edit-netzwerk, .overlay-jobalarm, .overlay-bewerbung, .overlay-passwort-vergessen, .overlay-bewerbung-ansehen, .overlay-dark-bg{
  background-color: rgba(0, 0, 0, 0.5);
}

.overlay-container{
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  transform: translate(-50%, -50%);
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  width: 600px;
  max-width: calc(100% - 2rem);
}

.loading-overlay{
  position: fixed;
  overflow: hidden;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.loading-overlay .loader-container{
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  transform: translate(-50%, -50%);
}
.loading-overlay .loader {
  width: 50px;
  display: inline-block;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.dashboard-container{
  margin-left: auto;
  margin-right: auto;
  display: grid;
  max-width: 1600px;
  grid-template-columns: repeat(12, 1fr);
  gap: 2rem;
}

@media (max-width: 1100px){
  .dashboard-container{
    gap: 0px;
  }
}

.dashboard-container{
  padding-bottom: 4rem;
}
.dashboard-container .dashboard-menu{
  grid-column: span 3 / span 3;
  grid-column-start: 1;
}
@media (max-width: 1100px) {
  .dashboard-container .dashboard-menu{
    grid-column: span 12 / span 12;
  }
  .dashboard-container .dashboard-menu{
    grid-column-start: 1;
  }
  .dashboard-container .dashboard-menu{
    margin-bottom: 1rem;
  }
  .dashboard-container .dashboard-menu{
    padding-right: 0px;
  }
}
.dashboard-container .dashboard-content{
  grid-column: span 9 / span 9;
  grid-column-start: 4;
}
@media (max-width: 1100px) {
  .dashboard-container .dashboard-content{
    grid-column: span 12 / span 12;
  }
  .dashboard-container .dashboard-content{
    grid-column-start: 1;
  }
}
.dashboard-container .dashboard-content .content-wrapper{
  margin-bottom: 2rem;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 3rem;
}
@media (max-width: 1100px){
  .dashboard-container .dashboard-content .content-wrapper{
    padding: 2rem;
  }
}
.dashboard-container .dashboard-content label{
  display: block;
  padding-bottom: 0.5rem;
}
@media (max-width: 768px){
  .dashboard-container .dashboard-content label.no-padding{
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
.dashboard-container .dashboard-content label.btn{
  display: inline-block;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.dashboard-container .dashboard-content input:not(.multiselect__input){
  height: 3rem;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(26 26 26 / var(--tw-border-opacity));
  border-radius: 0.5rem;
}
.dashboard-container .dashboard-content input:not(.multiselect__input).hidden {
  display: none;
}
.dashboard-container .dashboard-content textarea{
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(26 26 26 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(239 239 239 / var(--tw-bg-opacity));
  border-radius: 0.5rem;
  height: 12rem;
  width: 100%;
  padding: 1rem;
}
.dashboard-container .dashboard-content .select-wrapper{
  position: relative;
  height: 3rem;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(26 26 26 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(116 116 116 / var(--tw-text-opacity));
  border-radius: 0.5rem;
}
@media (max-width: 768px){
  .dashboard-container .dashboard-content .select-wrapper.order-select{
    height: 1.5rem;
  }
  .dashboard-container .dashboard-content .select-wrapper.order-select{
    width: 0.5rem;
  }
  .dashboard-container .dashboard-content .select-wrapper.order-select{
    border-style: none;
  }
}
.dashboard-container .dashboard-content .order-select{
  width: 8rem;
}
@media (max-width: 768px){
  .dashboard-container .dashboard-content .order-select select{
    top: auto;
  }
}
.dashboard-container .dashboard-content label.lightswitch{
  width: auto;
}
.dashboard-container .dashboard-content .multiselect__placeholder{
  white-space: nowrap;
  font-size: 1rem;
  --tw-text-opacity: 1;
  color: rgb(26 26 26 / var(--tw-text-opacity));
}
.dashboard-container .dashboard-content .multiselect__tags{
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(26 26 26 / var(--tw-border-opacity));
  border-radius: 0.5rem;
  overflow: hidden;
}
.dashboard-container .dashboard-content .multiselect__tag {
  padding: 8px 26px 8px 10px;
}
.dashboard-container .dashboard-content .multiselect__tag-icon::after{
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  transform: translate(-50%, -50%);
}
.dashboard-container .dashboard-content .multiselect__input{
  opacity: 0;
}

.dashboard-link{
  padding: 0.5rem;
  padding-left: 1.5rem;
  border-radius: 0.5rem;
  position: relative;
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.dashboard-link:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(234 249 242 / var(--tw-bg-opacity));
}
.dashboard-link.active{
  --tw-bg-opacity: 1;
  background-color: rgb(63 206 136 / var(--tw-bg-opacity));
}
.dashboard-link.active i{
  --tw-text-opacity: 1;
  color: rgb(26 26 26 / var(--tw-text-opacity));
}
.dashboard-link.logout:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(255 40 40 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.dashboard-link.logout i{
  --tw-text-opacity: 1;
  color: rgb(255 40 40 / var(--tw-text-opacity));
}
.dashboard-link i{
  --tw-text-opacity: 1;
  color: rgb(63 206 136 / var(--tw-text-opacity));
}

.file{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 768px){
  .file{
    flex-direction: column;
  }
  .file{
    align-items: flex-start;
  }
}

.file{
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(239 239 239 / var(--tw-bg-opacity));
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.file.locked{
  --tw-bg-opacity: 1;
  background-color: rgb(239 239 239 / var(--tw-bg-opacity));
}

.delete-link{
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  --tw-text-opacity: 1;
  color: rgb(255 40 40 / var(--tw-text-opacity));
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.delete-link i{
  display: none;
  font-size: 1.375rem;
}
.dashboard-container .dashboard-content input:not(.multiselect__input).delete-link i {
  display: none;
}
.delete-link:hover i{
  display: block;
}

.vue-map{
  border-radius: 0.5rem;
}

/* The switch - the box around the slider */
.switch{
  position: relative;
  display: inline-block;
  height: 1.5rem;
  width: 3rem;
  flex-grow: 0;
  /* do not grow   - initial value: 0 */
  flex-shrink: 0;
  /* do not shrink - initial value: 1 */
  flex-basis: 3rem;
  /* width/height  - initial value: auto */
}

/* Hide default HTML checkbox */
.switch input{
  height: 0px;
  width: 0px;
  opacity: 0;
}

/* The slider */
.slider{
  position: absolute;
  cursor: pointer;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  --tw-bg-opacity: 1;
  background-color: rgb(116 116 116 / var(--tw-bg-opacity));
  border-radius: 9999px;
  transition: 0.4s;
}

.slider:before{
  position: absolute;
  height: 1rem;
  width: 1rem;
  left: 0.25rem;
  bottom: 0.25rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: 9999px;
  content: "";
  transition: 0.4s;
}

input:checked + .slider{
  --tw-bg-opacity: 1;
  background-color: rgb(63 206 136 / var(--tw-bg-opacity));
}

input:checked + .slider:before {
  transform: translateX(24px);
}

/* Customize the label (the container) */
.checkmark-container{
  position: relative;
  display: flex;
  cursor: pointer;
  flex-direction: row-reverse;
  align-items: center;
  gap: 1rem;
  -webkit-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkmark-container input[type=checkbox]{
  position: absolute;
  height: 0px;
  width: 0px;
  cursor: pointer;
  opacity: 0;
}

/* Create a custom checkbox */
.checkmark{
  position: relative;
  height: 1rem;
  width: 1rem;
  border-radius: 0.125rem;
  --tw-bg-opacity: 1;
  background-color: rgb(239 239 239 / var(--tw-bg-opacity));
}

/* On mouse-over, add a grey background color */
.checkmark-container:hover input[type=checkbox] ~ .checkmark{
  --tw-bg-opacity: 1;
  background-color: rgb(116 116 116 / var(--tw-bg-opacity));
}

/* When the checkbox is checked, add a blue background */
.checkmark-container input[type=checkbox]:checked ~ .checkmark{
  --tw-bg-opacity: 1;
  background-color: rgb(63 206 136 / var(--tw-bg-opacity));
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after{
  position: absolute;
  display: none;
}
.dashboard-container .dashboard-content input:not(.multiselect__input).checkmark:after {
  display: none;
}
.checkmark:after {
  content: "";
}

/* Show the checkmark when checked */
.checkmark-container input[type=checkbox]:checked ~ .checkmark:after{
  display: block;
}

/* Style the checkmark/indicator */
.checkmark-container .checkmark:after{
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

input.search-input{
  text-overflow: ellipsis;
  border-style: none;
  --tw-bg-opacity: 1;
  background-color: rgb(239 239 239 / var(--tw-bg-opacity));
  padding-left: 3rem !important;
}
input.search-input::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  background: url("assets/images/times.svg");
  background-size: contain;
  background-position: center 100%;
  background-repeat: no-repeat;
}

input.search-input::placeholder{
  --tw-text-opacity: 1;
  color: rgb(26 26 26 / var(--tw-text-opacity));
}

.search-input-icon{
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

.btn{
  border-radius: 9999px;
  font-size: 1rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  cursor: pointer;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(26 26 26 / var(--tw-bg-opacity));
}

@media (min-width: 1200px){
  .btn:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(63 206 136 / var(--tw-bg-opacity));
  }
}

.btn{
  white-space: nowrap;
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

@media (min-width: 1200px){
  .btn:hover{
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}
.btn:disabled:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(26 26 26 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.btn:disabled{
  cursor: not-allowed;
}

.btn-white{
  border-radius: 9999px;
  font-size: 1rem;
  --tw-text-opacity: 1;
  color: rgb(26 26 26 / var(--tw-text-opacity));
  cursor: pointer;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

@media (min-width: 1200px){
  .btn-white:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(26 26 26 / var(--tw-bg-opacity));
  }
  .btn-white:hover{
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}

.btn-white{
  white-space: nowrap;
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.btn-white-hover{
  border-radius: 9999px;
  font-size: 1rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  cursor: pointer;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(26 26 26 / var(--tw-bg-opacity));
}

@media (min-width: 1200px){
  .btn-white-hover:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }
  .btn-white-hover:hover{
    --tw-text-opacity: 1;
    color: rgb(26 26 26 / var(--tw-text-opacity));
  }
}

.btn-white-hover{
  white-space: nowrap;
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.btn-red{
  border-radius: 9999px;
  font-size: 1rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  cursor: pointer;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 40 40 / var(--tw-bg-opacity));
}

@media (min-width: 1200px){
  .btn-red:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(26 26 26 / var(--tw-bg-opacity));
  }
  .btn-red:hover{
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}

.btn-red{
  white-space: nowrap;
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.btn-gray{
  border-radius: 9999px;
  font-size: 1rem;
  --tw-text-opacity: 1;
  color: rgb(26 26 26 / var(--tw-text-opacity));
  cursor: pointer;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(239 239 239 / var(--tw-bg-opacity));
}

@media (min-width: 1200px){
  .btn-gray:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(26 26 26 / var(--tw-bg-opacity));
  }
  .btn-gray:hover{
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}

.btn-gray{
  white-space: nowrap;
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.btn-green{
  border-radius: 9999px;
  font-size: 1rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  cursor: pointer;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(63 206 136 / var(--tw-bg-opacity));
}

@media (min-width: 1200px){
  .btn-green:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(26 26 26 / var(--tw-bg-opacity));
  }
  .btn-green:hover{
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}

.btn-green{
  white-space: nowrap;
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.btn-round{
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.btn-small{
  border-radius: 9999px;
  font-size: 0.875rem;
  --tw-text-opacity: 1;
  color: rgb(26 26 26 / var(--tw-text-opacity));
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(26 26 26 / var(--tw-border-opacity));
  cursor: pointer;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

@media (min-width: 1200px){
  .btn-small:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(26 26 26 / var(--tw-bg-opacity));
  }
  .btn-small:hover{
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}

.btn-small{
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.btn-small-active{
  border-radius: 9999px;
  font-size: 0.875rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(26 26 26 / var(--tw-border-opacity));
  cursor: pointer;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  --tw-bg-opacity: 1;
  background-color: rgb(26 26 26 / var(--tw-bg-opacity));
}

@media (min-width: 1200px){
  .btn-small-active:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }
  .btn-small-active:hover{
    --tw-text-opacity: 1;
    color: rgb(26 26 26 / var(--tw-text-opacity));
  }
}

.btn-small-active{
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

label{
  display: block;
  padding-bottom: 0.5rem;
}
@media (max-width: 768px){
  label.no-padding{
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
label.btn{
  padding-top: 1rem;
  padding-bottom: 1rem;
}

input:not(.multiselect__input){
  height: 3rem;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(26 26 26 / var(--tw-border-opacity));
  border-radius: 0.5rem;
}

input[type=checkbox]{
  display: inline-block;
  height: 1rem;
  width: 1rem;
}

.select-wrapper{
  position: relative;
  height: 3rem;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(26 26 26 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(116 116 116 / var(--tw-text-opacity));
  border-radius: 0.5rem;
}

select{
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  border-radius: 0.5rem;
  border-width: 0px;
  padding-left: 1rem;
  padding-right: 1rem;
  /* styling */
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;
  /* reset */
  margin: 0;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("assets/images/dropdown.svg");
  background-position: calc(100% - 20px) calc(1em + 2px);
  background-size: 12px 12px;
  background-repeat: no-repeat;
}
select:focus, select:active, select:focus-visible, select:focus-within{
  border-style: none;
  outline: 2px solid transparent;
  outline-offset: 2px;
}
select:focus {
  background: url("assets/images/dropdown-up.svg");
  background-position: calc(100% - 20px) calc(1em + 2px);
  background-size: 12px 12px;
  background-repeat: no-repeat;
  border-color: gray;
  outline: 0;
}
select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.radio-label{
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  --tw-shadow-colored: 0 3px 6px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@media (min-width: 1200px){
  .radio-label:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(234 249 242 / var(--tw-bg-opacity));
  }
}

.radio-label{
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.radio-label.paket-card{
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(26 26 26 / var(--tw-text-opacity));
}
@media (min-width: 1200px){
  .radio-label.paket-card:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(234 249 242 / var(--tw-bg-opacity));
  }
}
.radio-label.paket-card {
  padding-bottom: 1rem !important;
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

input:checked ~ .radio-label{
  --tw-bg-opacity: 1;
  background-color: rgb(63 206 136 / var(--tw-bg-opacity));
}

@media (min-width: 1200px){
  input:checked ~ .radio-label:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(63 206 136 / var(--tw-bg-opacity));
  }
}

input:checked ~ .radio-label{
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
input:checked ~ .radio-label.paket-card{
  --tw-bg-opacity: 1;
  background-color: rgb(63 206 136 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(26 26 26 / var(--tw-text-opacity));
}

.custom-radio .custom-radio-label{
  display: flex;
  align-items: center;
  gap: 1rem;
}
.custom-radio .custom-radio-label .check-circle{
  height: 1rem;
  width: 1rem;
  border-radius: 9999px;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(26 26 26 / var(--tw-border-opacity));
}
.custom-radio input:checked ~ .custom-radio-label .check-circle{
  height: 1rem;
  width: 1rem;
  --tw-border-opacity: 1;
  border-color: rgb(63 206 136 / var(--tw-border-opacity));
  border-width: 5px;
}

.multiselect .multiselect__select {
  right: 6px !important;
  top: 5px;
}
.multiselect .multiselect__select::before{
  position: absolute;
  right: auto;
  height: 0.5rem;
  width: 0.5rem;
  top: 10px;
  left: 16px;
  border-color: #000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.multiselect.multiselect--active:not(.multiselect--above) .multiselect__tags{
  border-radius: 0.5rem;
  overflow: hidden;
}
.multiselect .multiselect__content-wrapper{
  border-radius: 0.5rem;
}
.multiselect .multiselect__option.multiselect__option--highlight{
  --tw-bg-opacity: 1;
  background-color: rgb(63 206 136 / var(--tw-bg-opacity));
}
.multiselect .multiselect__option.multiselect__option--highlight::after{
  display: none;
}
.dashboard-container .dashboard-content input:not(.multiselect__input).multiselect .multiselect__option.multiselect__option--highlight::after {
  display: none;
}
.multiselect .multiselect__option.multiselect__option--selected::after{
  display: none;
}
.dashboard-container .dashboard-content input:not(.multiselect__input).multiselect .multiselect__option.multiselect__option--selected::after {
  display: none;
}

.multiselect__tags-wrap .multiselect__tag{
  border-radius: 0.5rem;
}
.multiselect__tags-wrap .multiselect__tag .multiselect__tag-icon:after{
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.quillWrapper{
  border-radius: 0.5rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(26 26 26 / var(--tw-border-opacity));
}
.quillWrapper .ql-toolbar{
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.quillWrapper .ql-container{
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.payment-form-fields .card-holder-first-name{
  margin-bottom: 1rem;
}
.payment-form-fields .card-holder-last-name{
  margin-bottom: 1rem;
}
.payment-form-fields .CardNumberField-input-wrapper input{
  height: 3rem;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(26 26 26 / var(--tw-border-opacity));
  border-radius: 0.5rem;
}

.__PrivateStripeElement{
  top: 0.75rem;
}

.craft-flash{
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  height: auto;
  pointer-events: none;
  text-align: center;
  z-index: 1000;
}

.flash-index {
  z-index: 1000;
}

.flash{
  display: inline-block;
  padding: 1.5rem;
  vertical-align: top;
  font-size: 1rem;
  border-radius: 0.5rem;
  --tw-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  --tw-shadow-colored: 0 3px 6px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  grid-column-start: 4;
  grid-column-end: 13;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flash.success{
  --tw-bg-opacity: 1;
  background-color: rgb(63 206 136 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.flash.error{
  --tw-bg-opacity: 1;
  background-color: rgb(255 40 40 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.not-dashboard .flash{
  grid-column-start: 4;
  grid-column-end: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.craft-flash-fake{
  position: fixed;
  left: 0px;
  right: 0px;
  top: 7rem;
  height: auto;
  padding-left: 3rem;
  padding-right: 3rem;
  text-align: center;
}

@media (max-width: 993px){
  .craft-flash-fake{
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (max-width: 768px){
  .craft-flash-fake{
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.craft-flash-fake {
  z-index: 999;
}

.side-menu{
  margin-bottom: 2rem;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 2rem;
}

.search-menu{
  margin-bottom: 2rem;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 2rem;
}

@media (max-width: 1100px){
  .search-menu{
    padding: 0px;
  }
}
.search-menu .multiselect__placeholder{
  white-space: nowrap;
  padding-top: 0.25rem;
  font-size: 1rem;
  --tw-text-opacity: 1;
  color: rgb(116 116 116 / var(--tw-text-opacity));
}
.search-menu .multiselect__select{
  z-index: 10;
}
.search-menu .multiselect__tags{
  width: 100%;
  padding-left: 1rem;
  padding-right: 2.5rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(26 26 26 / var(--tw-border-opacity));
  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;
}
.search-menu .multiselect__tag {
  padding: 8px 26px 8px 10px;
  white-space: initial;
  text-overflow: initial;
}
.search-menu .multiselect__tag-icon::after{
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  transform: translate(-50%, -50%);
}
.search-menu .multiselect__input{
  opacity: 0;
}
.search-menu .multiselect__single{
  padding-top: 0.25rem;
  font-size: 1rem;
  --tw-text-opacity: 1;
  color: rgb(116 116 116 / var(--tw-text-opacity));
}
.search-menu .vue-slider-rail{
  --tw-bg-opacity: 1;
  background-color: rgb(239 239 239 / var(--tw-bg-opacity));
}
.search-menu .vue-slider-process{
  --tw-bg-opacity: 1;
  background-color: rgb(63 206 136 / var(--tw-bg-opacity));
}
.search-menu .vue-slider-dot-handle{
  --tw-border-opacity: 1;
  border-color: rgb(63 206 136 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(63 206 136 / var(--tw-bg-opacity));
}
.search-menu .vue-slider:hover .vue-slider-rail{
  --tw-bg-opacity: 1;
  background-color: rgb(239 239 239 / var(--tw-bg-opacity));
}
.search-menu .vue-slider:hover .vue-slider-process{
  --tw-bg-opacity: 1;
  background-color: rgb(63 206 136 / var(--tw-bg-opacity));
}
.search-menu .vue-slider:hover .vue-slider-dot-handle{
  --tw-border-opacity: 1;
  border-color: rgb(63 206 136 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(63 206 136 / var(--tw-bg-opacity));
}

.jobalarm-menu{
  margin-bottom: 2rem;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(63 206 136 / var(--tw-bg-opacity));
  padding: 2rem;
  background: linear-gradient(to right top, #ffff4f 10%, #2dca7c 70%, #2dafb3 100%);
}

.overlay-jobalarm .multiselect__placeholder{
  white-space: nowrap;
  padding-top: 0.25rem;
  font-size: 1rem;
  --tw-text-opacity: 1;
  color: rgb(116 116 116 / var(--tw-text-opacity));
}
.overlay-jobalarm .multiselect__select{
  z-index: 10;
}
.overlay-jobalarm .multiselect__tags{
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(26 26 26 / var(--tw-border-opacity));
  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;
}
.overlay-jobalarm .multiselect__tag {
  padding: 8px 26px 8px 10px;
}
.overlay-jobalarm .multiselect__tag-icon::after{
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  transform: translate(-50%, -50%);
}
.overlay-jobalarm .multiselect__input{
  opacity: 0;
}
.overlay-jobalarm .multiselect__single{
  padding-top: 0.25rem;
  font-size: 1rem;
  --tw-text-opacity: 1;
  color: rgb(116 116 116 / var(--tw-text-opacity));
}

.notification-number{
  position: absolute;
  right: 0px;
  top: 0px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 40 40 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  height: 1rem;
  width: 1rem;
  font-size: .75rem;
  border-radius: 9999px;
  text-align: center;
}

.mobile-nav-button{
  text-align: center;
  width: 30px;
}

.bewerbungen-wrapper{
  padding-top: 2rem;
}

@media (max-width: 768px){
  .bewerbungen-wrapper{
    padding-top: 1rem;
  }
}

.bewerbungen{
  margin-top: 2rem;
  border-top-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(63 206 136 / var(--tw-border-opacity));
}

@media (max-width: 768px){
  .bewerbungen{
    margin-top: 1rem;
  }
}

.bewerbung{
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(63 206 136 / var(--tw-border-opacity));
}

@media (max-width: 768px){
  .bewerbung{
    flex-direction: column;
  }
}

.pages{
  margin-top: 1.5rem;
}

.page-switcher{
  text-align: center;
}

.page-numbers{
  height: 3rem;
  width: 3rem;
  border-radius: 9999px;
}
.page-numbers.active{
  --tw-bg-opacity: 1;
  background-color: rgb(26 26 26 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.status{
  border-radius: 9999px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0px;
  padding-bottom: 0px;
}
.status.Unbeantwortet{
  --tw-bg-opacity: 1;
  background-color: rgb(253 243 228 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(240 173 78 / var(--tw-text-opacity));
}
.status.unbeantwortet{
  --tw-bg-opacity: 1;
  background-color: rgb(253 243 228 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(240 173 78 / var(--tw-text-opacity));
}
.status.neu, .status.non-répondu{
  --tw-bg-opacity: 1;
  background-color: rgb(239 239 239 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(116 116 116 / var(--tw-text-opacity));
}
.status.in-der-auswahl, .status.en-cours-de-sélection{
  --tw-bg-opacity: 1;
  background-color: rgb(253 243 228 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(240 173 78 / var(--tw-text-opacity));
}
.status.abgelehnt, .status.refusé{
  --tw-bg-opacity: 1;
  background-color: rgb(251 227 227 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 40 40 / var(--tw-text-opacity));
}
.status.angestellt, .status.employé{
  --tw-bg-opacity: 1;
  background-color: rgb(226 248 237 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(63 206 136 / var(--tw-text-opacity));
}
.status.einladung-vorstellungsgespräch-bestätigt, .status.entretien-dembauche--invitation-confirmée{
  --tw-bg-opacity: 1;
  background-color: rgb(226 248 237 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(63 206 136 / var(--tw-text-opacity));
}
.status.unterlagen-erhalten, .status.documents-reçus{
  --tw-bg-opacity: 1;
  background-color: rgb(226 248 237 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(63 206 136 / var(--tw-text-opacity));
}
.status.einladung-vorstellungsgespräch-gesendet, .status.entretien-dembauche--invitation-envoyée{
  --tw-bg-opacity: 1;
  background-color: rgb(239 239 239 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(116 116 116 / var(--tw-text-opacity));
}
.status.unterlagen-angefordert, .status.documents-demandés{
  --tw-bg-opacity: 1;
  background-color: rgb(239 239 239 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(116 116 116 / var(--tw-text-opacity));
}
.status.um-geduld-gebeten, .status.patience-demandée{
  --tw-bg-opacity: 1;
  background-color: rgb(239 239 239 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(116 116 116 / var(--tw-text-opacity));
}
.status.stelle-besetzt{
  --tw-bg-opacity: 1;
  background-color: rgb(239 239 239 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(116 116 116 / var(--tw-text-opacity));
}
.status.offen{
  --tw-bg-opacity: 1;
  background-color: rgb(253 243 228 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(240 173 78 / var(--tw-text-opacity));
}
.status.in-bearbeitung{
  --tw-bg-opacity: 1;
  background-color: rgb(253 243 228 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(240 173 78 / var(--tw-text-opacity));
}
.status.absage{
  --tw-bg-opacity: 1;
  background-color: rgb(251 227 227 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 40 40 / var(--tw-text-opacity));
}
.status.eingeladen{
  --tw-bg-opacity: 1;
  background-color: rgb(226 248 237 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(63 206 136 / var(--tw-text-opacity));
}
.status.angenommen{
  --tw-bg-opacity: 1;
  background-color: rgb(226 248 237 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(63 206 136 / var(--tw-text-opacity));
}
.status.zusage{
  --tw-bg-opacity: 1;
  background-color: rgb(226 248 237 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(63 206 136 / var(--tw-text-opacity));
}
.status.veröffentlicht{
  --tw-bg-opacity: 1;
  background-color: rgb(226 248 237 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(63 206 136 / var(--tw-text-opacity));
}
.status.entwurf{
  --tw-bg-opacity: 1;
  background-color: rgb(224 243 244 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(45 175 179 / var(--tw-text-opacity));
}
.status.abgelaufen{
  --tw-bg-opacity: 1;
  background-color: rgb(251 227 227 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 40 40 / var(--tw-text-opacity));
}
.status.inaktiv{
  --tw-bg-opacity: 1;
  background-color: rgb(251 227 227 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 40 40 / var(--tw-text-opacity));
}
.status.aktiv{
  --tw-bg-opacity: 1;
  background-color: rgb(226 248 237 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(63 206 136 / var(--tw-text-opacity));
}

.job-mit-bewerbung{
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(63 206 136 / var(--tw-border-opacity));
}

.circle-dropdown{
  position: absolute;
  right: 0px;
  z-index: 10;
  display: flex;
  width: 16rem;
  flex-direction: column;
  gap: 2rem;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 2rem;
  --tw-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  --tw-shadow-colored: 0 3px 6px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  top: 80%;
}

.no-circle-dropdown{
  position: absolute;
  top: 2rem;
  right: 0px;
  z-index: 10;
  display: flex;
  width: 14rem;
  flex-direction: column;
  gap: 2rem;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 2rem;
  --tw-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  --tw-shadow-colored: 0 3px 6px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.job{
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 2rem;
  --tw-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  --tw-shadow-colored: 0 3px 6px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 768px){
  .job{
    flex-direction: column;
  }
  .job{
    align-items: flex-start;
  }
  .job{
    gap: 1rem;
  }
}

.job{
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

@media (min-width: 1200px){
  .job:hover{
    --tw-scale-x: 1.03;
    --tw-scale-y: 1.03;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}
.job:hover .job-titel{
  --tw-text-opacity: 1;
  color: rgb(63 206 136 / var(--tw-text-opacity));
}

.jobs-list{
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.job-alarm-job{
  border-radius: 0.5rem;
  padding: 2rem;
  --tw-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  --tw-shadow-colored: 0 3px 6px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 768px){
  .job-alarm-job{
    flex-direction: column;
  }
  .job-alarm-job{
    row-gap: 1rem;
  }
}

.job-alarm-job {
  background: linear-gradient(to right, #ffff4f 10%, #2dca7c 70%, #2dafb3 100%);
}

.job-alarm-icon{
  height: 6rem;
  width: 6rem;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  background-size: cover;
  background-position: center;
  --tw-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  --tw-shadow-colored: 0 3px 6px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@media (max-width: 768px){
  .job-alarm-icon{
    height: 5rem;
  }
  .job-alarm-icon{
    width: 5rem;
  }
}

.job-alarm-icon {
  background-image: url("assets/images/jobalarm-icon.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

@media (min-width: 1200px){
  .isFavorit:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(63 206 136 / var(--tw-bg-opacity));
  }
}
.isFavorit .normalHeart{
  display: block;
}
.isFavorit .hoverHeart{
  display: none;
}
.dashboard-container .dashboard-content input:not(.multiselect__input).isFavorit .hoverHeart {
  display: none;
}
.isFavorit:hover .normalHeart{
  display: none;
}
.dashboard-container .dashboard-content input:not(.multiselect__input).isFavorit:hover .normalHeart {
  display: none;
}
.isFavorit:hover .hoverHeart{
  display: block;
}

.isNotFavorit{
  --tw-bg-opacity: 1;
  background-color: rgb(239 239 239 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(63 206 136 / var(--tw-text-opacity));
}

@media (min-width: 1200px){
  .isNotFavorit:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(63 206 136 / var(--tw-bg-opacity));
  }
}
.isNotFavorit .normalHeart{
  display: none;
}
.dashboard-container .dashboard-content input:not(.multiselect__input).isNotFavorit .normalHeart {
  display: none;
}
.isNotFavorit .hoverHeart{
  display: block;
}

.paket-card{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 0.5rem;
  padding: 2rem;
  --tw-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  --tw-shadow-colored: 0 3px 6px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.paket-card.to-cart{
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(26 26 26 / var(--tw-text-opacity));
}
.paket-card.to-cart:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(63 206 136 / var(--tw-bg-opacity));
}
.paket-card.to-cart{
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
@media (min-width: 1200px) {
  .paket-card.to-cart:hover{
    --tw-scale-x: 1.03;
    --tw-scale-y: 1.03;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .paket-card.to-cart:hover button{
    --tw-bg-opacity: 1;
    background-color: rgb(26 26 26 / var(--tw-bg-opacity));
  }
  .paket-card.to-cart:hover button{
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}

.job-content{
  grid-column: span 9 / span 9;
  grid-column-start: 1;
}
@media (max-width: 1100px) {
  .job-content{
    grid-column: span 12 / span 12;
  }
  .job-content{
    grid-column-start: 1;
  }
}

.job-sidebar{
  grid-column: span 3 / span 3;
  grid-column-start: 10;
}
@media (max-width: 1100px) {
  .job-sidebar{
    grid-column: span 12 / span 12;
  }
  .job-sidebar{
    grid-column-start: 1;
  }
  .job-sidebar{
    margin-bottom: 1rem;
  }
}

.redactor-content a{
  --tw-text-opacity: 1;
  color: rgb(63 206 136 / var(--tw-text-opacity));
}

.redactor-content a:hover{
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.redactor-content p{
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.redactor-content i, .redactor-content em{
  font-weight: 500;
}
.redactor-content blockquote{
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-left: 1.5rem;
  --tw-border-opacity: 1;
  border-color: rgb(63 206 136 / var(--tw-border-opacity));
  padding-left: 0.75rem;
  border-left-width: 3px;
  font-style: italic;
}
.redactor-content strong{
  font-size: 1.375rem;
}
.redactor-content ul {
  list-style: none;
}
.redactor-content ul > li {
  position: relative;
  display: block;
  overflow: hidden;
  margin-left: 0;
  padding-left: 1rem;
  text-indent: -0.5rem;
}
.redactor-content ul > li:before {
  content: "";
  transform: translateX(-100%);
  display: inline-block;
  margin-bottom: 0.125em;
  vertical-align: baseline;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(63 206 136 / var(--tw-bg-opacity));
}
.redactor-content ul > li + li{
  margin-top: 0.5rem;
}
.redactor-content ul > li > a {
  text-indent: 0;
}
.redactor-content ol {
  list-style: decimal;
  padding-left: 1rem;
}
.redactor-content .all-big{
  font-size: 1.375rem;
}
.redactor-content .text-big p{
  font-size: 1.375rem;
}

.gallery{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, minmax(0, 1fr));
  gap: 1rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media (max-width: 993px){
  .gallery{
    grid-template-columns: repeat(2, 1fr);
  }
}
.gallery .bild{
  position: relative;
  cursor: pointer;
  border-radius: 0.5rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 65%;
}
.gallery .bild .filter-layer{
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border-radius: 0.125rem;
  background-color: rgba(46, 59, 78, 0.5);
}
.gallery .bild .open-button{
  position: absolute;
  top: 50%;
  left: 50%;
  height: 3rem;
  width: 3rem;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  transform: translate(-50%, -50%);
}
.gallery .bild:first-child {
  margin-left: 0;
}

.lightbox{
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.9);
}
.lightbox .close-button{
  position: fixed;
  top: 3rem;
  right: 3rem;
  height: 3rem;
  width: 3rem;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.lightbox .prev-button{
  position: fixed;
  left: 3rem;
  top: 50%;
  height: 3rem;
  width: 3rem;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  transform: translate(0, -50%);
}
.lightbox .next-button{
  position: fixed;
  right: 3rem;
  top: 50%;
  transform: translate(0, -50%);
  height: 3rem;
  width: 3rem;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.lightbox .lightbox-container{
  position: fixed;
  right: 8rem;
  left: 8rem;
  top: 50%;
  height: 75%;
  max-height: 100%;
  max-width: 100%;
  transform: translate(0, -50%);
}
.lightbox .lightbox-container .lightbox-bild{
  height: 100%;
  width: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.lightbox .lightbox-container .lightbox-text{
  text-align: center;
}

.scroll-top-icon{
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(26 26 26 / var(--tw-text-opacity));
  --tw-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  --tw-shadow-colored: 0 3px 6px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  visibility: hidden;
  position: fixed;
  z-index: 999;
  cursor: pointer;
  bottom: 1.5rem;
  right: 1.5rem;
  opacity: 0;
  width: 45px;
  height: 45px;
  border-radius: 45px;
  transition: all 0.3s linear;
}
.scroll-top-icon.showing {
  opacity: 1;
  visibility: visible;
}
.scroll-top-icon i {
  position: absolute;
  font-size: 85%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.scroll-top-icon:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(63 206 136 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.testing-banner{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  --tw-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  --tw-shadow-colored: 0 3px 6px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(26 26 26 / var(--tw-text-opacity));
  max-width: calc(100vw - 3rem);
  visibility: hidden;
  position: fixed;
  z-index: 997;
  cursor: pointer;
  bottom: 5.5rem;
  right: 1.5rem;
  opacity: 0;
  transition: all 0.3s linear;
}
.testing-banner.showing {
  opacity: 1;
  visibility: visible;
}
@media (max-width: 768px) {
  .testing-banner.showing {
    visibility: hidden;
    opacity: 0;
  }
  .testing-banner.showing.isShownMobile {
    opacity: 1;
    visibility: visible;
  }
}
.testing-banner:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(63 206 136 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.testing-banner .testing-banner-img{
  background-size: cover;
  height: 29px;
  width: 29px;
  background-image: url("assets/images/jobalarm-icon.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

html{
  font-family: GTEesti, sans-serif;
  --tw-text-opacity: 1;
  color: rgb(26 26 26 / var(--tw-text-opacity));
}